import React, { useContext, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import { navigate } from '@reach/router';
import { DefaultTemplate } from '@luigiclaudio/ga-baseline-ui/template';
import { useHasMounted } from '@luigiclaudio/ga-baseline-ui/helpers';
import { Container, Row, Col, SiteContentWrapper } from '@luigiclaudio/ga-baseline-ui/layout';
import { AuthSignIn, useUser } from '@luigiclaudio/ga-auth-theme';
import MainContainer from '../components/pageLayout/MainContainer';
import locationPropTypes from '../prop-types/locationPropTypes';
import SiteFooter from '../components/pageLayout/SiteFooter';

const AuthSignInPage = ({ intl, location }) => {
    const [user] = useUser();
    const theme = useContext(ThemeContext);
    const hasMounted = useHasMounted();
    const renderBgColor = hasMounted ? theme.site.stage.backgroundColor : null;

    useEffect(() => {
        if (user) {
            navigate('/app');
        }
    }, [user]);

    return (
        <DefaultTemplate
            bgStart={renderBgColor}
            bgEnd={renderBgColor}
            content={
                <SiteContentWrapper>
                    <Container isFullHeight>
                        <Row isFullHeight justifyContent="center">
                            <Col xxs={30}>
                                <MainContainer>
                                    {hasMounted && !user && (
                                        <AuthSignIn
                                            siteName={intl.formatMessage({ id: 'siteName' })}
                                            navigateTo={location?.state?.pathname || '/app'}
                                            buttonSkin="muted"
                                        />
                                    )}
                                </MainContainer>
                            </Col>
                        </Row>
                    </Container>
                </SiteContentWrapper>
            }
            footer={<SiteFooter />}
        />
    );
};

AuthSignInPage.propTypes = {
    intl: PropTypes.shape().isRequired,
    location: locationPropTypes.location,
};

AuthSignInPage.defaultProps = {
    location: undefined,
};

export default injectIntl(AuthSignInPage);
